.wrapper {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  --s: 5px;
  /* control the size */
  width: calc(10 * var(--s));
  display: grid;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-mask: radial-gradient(
    50% 50%,
    #0000 calc(99% - 2 * var(--s)),
    #000 calc(101% - 2 * var(--s))
  );
  animation: r 4s linear infinite;
}

.loader:before {
  content: "";
  /* adjust the colors below */
  background: conic-gradient(
    from 25deg,
    #f8a201 25%,
    #fa2402 0 50%,
    #fdb3b0 0 75%,
    #02dde1 0
  );
  -webkit-mask: repeating-conic-gradient(#0000 0 25deg, #000 23% 25%),
    radial-gradient(var(--s) at var(--s) 50%, #000 97%, #0000)
      left/calc(100% - 2 * var(--s)) 100% repeat-x,
    radial-gradient(var(--s) at 50% var(--s), #000 97%, #0000) top / 100%
      calc(100% - 2 * var(--s)) repeat-y;
}

@keyframes r {
  to {
    transform: rotate(1turn);
  }
}
